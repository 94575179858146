import { create } from "zustand";

import { Locale } from "~/utils/interfaces/Locale";

export type CouponMethods = "amount" | "percentage";
export type CouponSelectors = CouponMethods & "all";
export type CouponStates = "active" | "inactive" | "all";

type CouponStore = {
  country: Locale;
  active: CouponStates;
  method: CouponSelectors;
  page: number;
  search: string | undefined;
  setCountry: (newValue: Locale) => void;
  setActive: (newValue: CouponStates) => void;
  setMethod: (newValue: CouponMethods) => void;
  setSearch: (newValue: string | undefined) => void;
  setPage: (newValue: number) => void;
  getActive: () => boolean | undefined;
  getMethod: () => CouponMethods | undefined;
};

const useCouponStore = create<CouponStore>((set, get) => ({
  country: "cl",
  active: "active",
  method: "all",
  page: 1,
  search: undefined,
  setCountry: (newValue: Locale) => set({ country: newValue, page: 1 }),
  setActive: (newValue: CouponStates) => set({ active: newValue, page: 1 }),
  setMethod: (newValue: CouponMethods) => set({ method: newValue, page: 1 }),
  setPage: (newValue: number) => set({ page: newValue }),
  setSearch: (newValue: string | undefined) =>
    newValue === "" ? set({ search: undefined, page: 1 }) : set({ search: newValue?.toUpperCase(), page: 1 }),
  getActive: () => {
    if (get().active === "active") {
      return true;
    } else if (get().active === "inactive") {
      return false;
    }
    return undefined;
  },
  getMethod: (): CouponMethods | undefined => {
    return get().method === "all" ? undefined : get().method;
  },
}));

export default useCouponStore;
