import React from "react";

import { navigate } from "gatsby";

import usePermissions from "~/hooks/usePermissions";
import useCouponStore, { CouponSelectors, CouponStates } from "~/stores/CouponStore";
import { Locale } from "~/utils/interfaces/Locale";

interface IProps {
  onSearch: () => void;
}

const CouponFilters = (props: IProps) => {
  const { isCouponAdmin } = usePermissions();
  const [country, setCountry, active, setActive, method, setMethod, search, setSearch] = useCouponStore((state) => [
    state.country,
    state.setCountry,
    state.active,
    state.setActive,
    state.method,
    state.setMethod,
    state.search,
    state.setSearch,
  ]);

  return (
    <div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-4 p-2">
          <input
            type="text"
            className="bg-examedi-light-gray-bg border border-examedi-gray-light text-sm rounded-lg focus:ring-examedi-blue-normal focus:border-examedi-blue-normal block p-2.5"
            placeholder="CÓDIGO"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <button
            className="bg-examedi-blue-normal text-examedi-white-pure text-sm rounded-lg block p-2.5"
            onClick={props.onSearch}
          >
            BUSCAR
          </button>
        </div>
        <div className="p-2">
          {isCouponAdmin && (
            <button
              className="bg-examedi-blue-normal text-examedi-white-pure text-sm rounded-lg block p-2.5"
              onClick={() => {
                navigate("/coupons/create/");
              }}
            >
              CREAR
            </button>
          )}
        </div>
      </div>
      <div className="flex flex-row gap-4 p-2">
        <select
          placeholder="País"
          id="countries"
          value={country}
          onChange={(e) => {
            setCountry(e.target.value as Locale);
          }}
          className="border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
        >
          <option value="cl">Chile</option>
          <option value="mx">México</option>
        </select>
        <select
          placeholder="Estado"
          id="state"
          value={active}
          onChange={(e) => {
            setActive(e.target.value as CouponStates);
          }}
          className="border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
        >
          <option value="all">Todos</option>
          <option value="active">Activo</option>
          <option value="inactive">Inactivo</option>
        </select>
        <select
          placeholder="Método"
          id="method"
          value={method}
          onChange={(e) => {
            setMethod(e.target.value as CouponSelectors);
          }}
          className="border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
        >
          <option value="all">Todos</option>
          <option value="amount">Monto</option>
          <option value="percentage">Porcentage</option>
        </select>
      </div>
    </div>
  );
};

export default CouponFilters;
