import React, { useEffect } from "react";

import { navigate } from "gatsby-link";

import CouponFilters from "~/components/Coupons/CouponFilters";
import TablePagination from "~/components/Tables/TablePagination";
import { WEEKDAYS } from "~/constants/coupons";
import useCancelableFetch from "~/hooks/useCancelableFetch";
import useCouponStore from "~/stores/CouponStore";
import couponsAPI from "~/utils/api/v2/coupons";
import { IBaseCoupon } from "~/utils/interfaces/coupons";

const CouponsList = () => {
  const [country, getMethod, method, getActive, active, search, page, setPage] = useCouponStore((state) => [
    state.country,
    state.getMethod,
    state.method,
    state.getActive,
    state.active,
    state.search,
    state.page,
    state.setPage,
  ]);
  const { isLoading, response, cancel, retry } = useCancelableFetch(couponsAPI.list, {
    active: getActive(),
    country,
    method: getMethod(),
    search: search,
    page,
  });
  const data: IBaseCoupon[] = response ? ("results" in response ? response.results : []) : [];
  const totalPages: number = Math.floor(((response as any)?.count - 1) / 20) + 1 || 1;

  const onSearch = () => {
    cancel();
    retry();
  };

  useEffect(() => {
    cancel();
    retry();
  }, [country, method, active, page]);

  return (
    <div className="m-4 bg-examedi-white-pure p-2 border rounded-md">
      <CouponFilters onSearch={onSearch} />
      {isLoading ? (
        <div> Cargando </div>
      ) : (
        <div>
          <div className="relative overflow-x-auto rounded-md">
            <table className="w-full text-sm text-left">
              <thead className="text-xs uppercase bg-examedi-gray-line">
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-2"
                  >
                    Código
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2"
                  >
                    Áctivo
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2"
                  >
                    Método
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2"
                  >
                    Descuento
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2"
                  >
                    Para todo servicio a domicilio
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2"
                  >
                    Telemedicina
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2"
                  >
                    Descuento máximo
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2"
                  >
                    Usos restantes
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2"
                  >
                    Deshabilitado los dias
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((row) => (
                  <tr
                    className="even:bg-examedi-gray-6 odd:bg-examedi-light-gray-bg"
                    key={row.referral_code}
                  >
                    <th
                      scope="row"
                      className="px-4 py-2 font-bold text-gray-900 whitespace-nowrap hover:cursor-pointer"
                      onClick={() => {
                        navigate(`/coupons/${row.id}/`);
                      }}
                    >
                      {row.referral_code}
                    </th>
                    <td className="px-4 py-2 text-center">{row.active ? "Sí" : "No"}</td>
                    <td className="px-4 py-2 text-center">{row.method === "percentage" ? "%" : "$"}</td>
                    <td className="px-4 py-2">{row.discount}</td>
                    <td className="px-4 py-2 text-center">{row.global_discount ? "Sí" : "No"}</td>
                    <td className="px-4 py-2 text-center">{row.telemedicine ? "Sí" : "No"}</td>
                    <td className="px-4 py-2">{row.max_discount}</td>
                    <td className="px-4 py-2">{row.remaining_uses}</td>
                    <td className="px-4 py-2">
                      {Object.entries(WEEKDAYS)
                        .reduce((acc, [key, value]) => {
                          return row.disabled_days.includes(key) ? [...acc, value.slice(0, 3)] : acc;
                        }, [])
                        .join(", ")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex flex-row w-full justify-end mt-2">
            <TablePagination
              pages={totalPages}
              currentPage={page}
              setPage={setPage}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CouponsList;
