import React from "react";

import PrivateRoute from "~/components/Authentication/PrivateRoute";
import CouponsList from "~/components/Coupons/CouponsList";

const CouponsIndex = () => {
  return (
    <PrivateRoute>
      <h1 className="text-3xl">Cupones de descuento</h1>
      <CouponsList />
    </PrivateRoute>
  );
};

export default CouponsIndex;
